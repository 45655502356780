
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { UnitDetail } from '@/types/product'
@Component
@KeepAlive
export default class UnitList extends Vue {
  private loading = false
  private info: Array<UnitDetail> = []

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.product.selectProductUnitByPage).then((res) => {
      this.info = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productUnitAdd'
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productUnitEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该计量单位吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteProductUnit, {
        unitId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
